import OktaAuth from '@okta/okta-auth-js';
import UserSessionService from 'services/navigation/userSession.service';
import { notifyError } from 'helpers/errorNotifier';
import { IS_PRODUCTION_MODE, publicEnvConfig } from 'helpers/env';
import { createAbsoluteUrlFromPath } from 'helpers/url';

const ENABLE_SINGLE_SIGN_ON = false;

export default class OktaService {
	static instance;

	static getInstance() {
		if (!this.instance) this.instance = new OktaService();
		return this.instance;
	}

	constructor() {
		this.oktaAuth = new OktaAuth({
			issuer: publicEnvConfig.OKTA_ISSUER,
			clientId: publicEnvConfig.OKTA_CLIENT_ID,
			devMode: !IS_PRODUCTION_MODE,
			redirectUri: createAbsoluteUrlFromPath('/sign_in'),
			pkce: true,
			responseType: 'code',
			scope: 'openid profile email',
		});
	}

	loginUsingOurForm = async (email, password, queryParamsToForward) => {
		const GENERIC_ERROR_RESPONSE = {
			success: false,
			errorMessage: 'There is an error trying to login with Okta, try again or contact support',
		};
		let tokenResponse;
		try {
			const transaction = await this.oktaAuth.signInWithCredentials({ username: email, password });
			if (transaction.status !== 'SUCCESS') return GENERIC_ERROR_RESPONSE;
			// Start a session using the sessionToken or redirect
			const sessionToken = transaction.sessionToken;

			tokenResponse = await this.oktaAuth.token.getWithoutPrompt({
				sessionToken,
				scopes: ['openid', 'profile', 'email'],
			});
		} catch (error) {
			notifyError(error, { metadata: { email } });
			return GENERIC_ERROR_RESPONSE;
		}

		try {
			await UserSessionService.instance.signIn(
				'',
				'',
				queryParamsToForward,
				tokenResponse.tokens.accessToken.accessToken,
			);
		} catch (error) {
			return { success: false, errorMessage: error?.error?.message || GENERIC_ERROR_RESPONSE.errorMessage };
		}

		return { success: true };
	};

	checkSession = async () => {
		if (!ENABLE_SINGLE_SIGN_ON) return false;
		const session = await this.oktaAuth.session.get();
		const isActiveSession = session.status === 'ACTIVE';
		return isActiveSession;
	};

	generateNewCodeForActiveSessions = async () => {
		const tokenResponse = await this.oktaAuth.token.getWithoutPrompt({
			scopes: ['openid', 'profile', 'email'],
		});
		await UserSessionService.instance.signIn('', '', {}, tokenResponse.tokens.accessToken.accessToken);
	};
}
